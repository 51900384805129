import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import { marked } from 'marked';
import '../css/main.scss';

Alpine.plugin(intersect)

const MY_API_URL = "https://secondemainlimousin.ddns.net/api"

document.addEventListener('alpine:init', () => {

  Alpine.data('accueil', () => ({
    title: document.querySelector("head > title").innerText,
    tab: 'accueil',
    init() {
	if(window.location.hash != ""){
		this.tab = window.location.hash.replace("#", "")
      		Alpine.store('lieux').setTab(this.tab)
      		Alpine.store('lieux').resetDataFilter()
		document.querySelector("head > title").innerText = this.title + " | " + this.tab.toUpperCase()
	}
    },
    toggleTab(e) {
      this.tab = e.target.getAttribute('href').replace("#", "")
      document.querySelector("head > title").innerText = this.title + " | " + this.tab.toUpperCase() 
      Alpine.store('lieux').setTab(this.tab)
      Alpine.store('lieux').resetDataFilter()
      return true
    },
    isActive(tab) {
      return tab === this.tab
    }
  }))

  Alpine.store('lieux', {
    data: [],
    dataFilter: [],
    tab: '',
    isLoaded: false,
    resetDataFilter() {
	if(this.tab == "coeur"){
		this.dataFilter = this.data.filter(o => o["Coup de Coeur"])
		return 
	}
	this.dataFilter = this.data 
    },
    setTab(tab){
	    this.tab = tab
    },
    filter() {
      let villeFilter = document.querySelector("#search-villes").value.toLowerCase()
      let typeFilter = document.querySelector("#search-type").value.toLowerCase()
      let searchFilter = document.querySelector("#search").value.toLowerCase()
      this.resetDataFilter()
      this.dataFilter = this.data.filter(d => {
        return d.ville.toLowerCase().match(villeFilter)
      })
      this.dataFilter = this.dataFilter.filter(d => {
	     let matched=false
	     for( const property in d){
		if(typeof d[property] == 'string' && d[property].toLowerCase().match(searchFilter)){
			matched=true
			break	
		}
	     }
	     return matched
      })
      this.dataFilter = this.dataFilter.filter(d => {
	     let matched=false
	     for( const property in d){
		if(property == 'type'){
			d[property].forEach(v => {
				if(v != "" && v.toLowerCase().match(typeFilter)){
					matched=true
				}
			})
		}
	     }
	     return matched
      })
    },
    init(){
	    this.getData()
    },
    getData() {
      fetch(`${MY_API_URL}/all`)
        .then(r => r.json())
        .then(r => {
          this.data = r 
	  this.resetDataFilter()
          this.isLoaded = true
        })
    }
  })

  //let carto = goGoCarto('#gogocarto', "https://secondemainlimousin.gogocarto.fr/api/gogocartojs-conf.json");

})
Alpine.start()
